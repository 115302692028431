// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import PiggybackLandingPage from './PiggybackLandingPage';
import PrivacyPolicy from './components/PrivacyPolicy'; 
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import SubmissionIndicator from './components/SubmissionIndicator';
import NotFound from './components/404NotFound.js';
import TermsOfService from './components/TermsOfService';
import CookiePolicy from './components/CookiePolicy';

function App() {
  return (
    <Router>
      <Navbar/>
      <div className="App">
        <Routes>
          <Route path="/" element={<PiggybackLandingPage />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/cookies" element={<CookiePolicy />} />
          <Route path="/stripesubmitted" element={<SubmissionIndicator />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="*" element={<NotFound />} /> {/* Handle unmatched routes */}
        </Routes>
      </div>
      <Footer/>
    </Router>
  );
}

export default App;
