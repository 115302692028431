// src/components/HowItWorksSection.js

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Package, Truck, MapPin, CheckCircle, UserPlus, CreditCard } from 'lucide-react';

const HowItWorksSection = () => {
  const [activeStep, setActiveStep] = useState(null);

  const steps = [
    { icon: Package, title: "List Your Item", description: "Describe your package and set your delivery preferences with our easy-to-use interface." },
    { icon: Truck, title: "Match with a Driver", description: "Our smart algorithm connects you with a reliable driver heading your way." },
    { icon: MapPin, title: "Real-Time Tracking", description: "Follow your package's journey in real-time with our advanced tracking system." },
    { icon: CheckCircle, title: "Secure Delivery", description: "Your item is safely delivered to its destination, with confirmation and feedback." },
  ];

  const driverSteps = [
    { icon: UserPlus, title: "Join Our Network", description: "Sign up as a driver and set your routes and availability." },
    { icon: Package, title: "Accept Deliveries", description: "Browse and accept delivery requests that match your journey." },
    { icon: Truck, title: "Pick Up & Deliver", description: "Collect the package and deliver it along your planned route." },
    { icon: CreditCard, title: "Get Paid Instantly", description: "Receive payment directly to your account upon successful delivery." },
  ];

  return (
    <section id="how-it-works" className="py-20 bg-gradient-to-b from-white to-ffc0c6/10">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center mb-16 text-gray-800"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          How Piggybackit Works
        </motion.h2>
        
        <div className="mb-20">
          <h3 className="text-2xl md:text-3xl font-bold text-center mb-10 text-gray-700">Sending a Package</h3>
          <Timeline steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
        </div>
        
        <div>
          <h3 className="text-2xl md:text-3xl font-bold text-center mb-10 text-gray-700">Becoming a Driver</h3>
          <Timeline steps={driverSteps} activeStep={activeStep} setActiveStep={setActiveStep} startIndex={5} />
        </div>
      </div>
    </section>
  );
};

const Timeline = ({ steps, activeStep, setActiveStep, startIndex = 1 }) => (
  <div className="relative">
    <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-ffc0c6"></div>
    {steps.map((step, index) => (
      <TimelineStep 
        key={index}
        step={step}
        index={index + startIndex}
        isActive={activeStep === index + startIndex}
        onActivate={() => setActiveStep(activeStep === index + startIndex ? null : index + startIndex)}
        isLeft={index % 2 === 0}
      />
    ))}
  </div>
);

const TimelineStep = ({ step, index, isActive, onActivate, isLeft }) => {
  const Icon = step.icon;
  return (
    <motion.div 
      className={`flex items-center mb-8 ${isLeft ? 'flex-row-reverse' : ''}`}
      initial={{ opacity: 0, x: isLeft ? -50 : 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <div className={`w-1/2 ${isLeft ? 'pl-4' : 'pr-4'} ${isLeft ? 'text-right' : 'text-left'}`}>
        <motion.div 
          className={`bg-white p-6 rounded-lg shadow-lg cursor-pointer transition-all duration-300 ${isActive ? 'scale-105 shadow-xl' : ''}`}
          whileHover={{ scale: 1.05 }}
          onClick={onActivate}
        >
          <div className="flex items-center mb-3">
            <Icon className="text-ffc0c6 mr-2" size={24} />
            <h4 className="text-xl font-semibold">{step.title}</h4>
          </div>
          {isActive && (
            <motion.p 
              className="text-gray-600"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              {step.description}
            </motion.p>
          )}
        </motion.div>
      </div>
      <div className="w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 flex items-center justify-center">
        <div className="bg-ffc0c6 w-8 h-8 rounded-full text-white flex items-center justify-center font-bold">
          {index}
        </div>
      </div>
    </motion.div>
  );
};

export default HowItWorksSection;
