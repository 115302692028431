// src/components/ImpactDashboard.js

import React from 'react';
import { motion } from 'framer-motion';
import { Leaf, Truck, Users, Package, DollarSign, TrendingUp } from 'lucide-react';

const ImpactDashboard = () => {
  return (
    <section id="impact" className="py-20 bg-gradient-to-b from-white to-ffc0c6/10">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center mb-16 text-gray-800"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Our Collective Impact
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <ImpactCard 
            icon={Leaf}
            title="CO₂ Emissions Saved"
            value={1500000}
            unit="kg"
            description="Reduced carbon footprint through efficient package delivery"
          />
          <ImpactCard 
            icon={Truck}
            title="Trips Optimized"
            value={250000}
            unit=""
            description="Delivery routes optimized for maximum efficiency"
          />
          <ImpactCard 
            icon={Users}
            title="Active Community Members"
            value={100000}
            unit="+"
            description="Growing network of senders and drivers"
          />
          <ImpactCard 
            icon={Package}
            title="Packages Delivered"
            value={5000000}
            unit=""
            description="Successfully delivered packages through our platform"
          />
          <ImpactCard 
            icon={DollarSign}
            title="Driver Earnings"
            value={10000000}
            unit="$"
            description="Extra income generated for our community of drivers"
          />
          <ImpactCard 
            icon={TrendingUp}
            title="Delivery Efficiency Increase"
            value={35}
            unit="%"
            description="Improvement in delivery times and resource utilization"
          />
        </div>
      </div>
    </section>
  );
};

const ImpactCard = ({ icon: Icon, title, value, unit, description }) => (
  <motion.div 
    className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
    whileHover={{ scale: 1.05 }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="flex items-center mb-4">
      <Icon size={32} className="text-ffc0c6 mr-3" />
      <h3 className="text-xl font-bold text-gray-800">{title}</h3>
    </div>
    <p className="text-3xl font-bold mb-2 text-ffc0c6">
      <CountUp end={value} />
      <span className="text-xl ml-1">{unit}</span>
    </p>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

const CountUp = ({ end, duration = 2000 }) => {
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    let startTimestamp;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setCount(Math.floor(progress * end));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }, [end, duration]);

  return <span>{count.toLocaleString()}</span>;
};

export default ImpactDashboard;
