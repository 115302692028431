// src/components/Footer.js

import React from 'react';
import { motion } from 'framer-motion';
import { Facebook, Twitter, Instagram, Linkedin, MapPin, Mail, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="relative bg-gradient-to-br from-gray-900 to-black text-white py-16 overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="md:col-span-1"
          >
            <h3 className="text-2xl font-bold mb-4 text-ffc0c6">Piggybackit</h3>
            <p className="text-gray-300 mb-4">It's On Someone's Way.</p>
            <div className="flex justify-start space-x-4">
              <SocialIcon icon={Facebook} />
              <SocialIcon icon={Twitter} />
              <SocialIcon icon={Instagram} />
              <SocialIcon icon={Linkedin} />
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="md:col-span-1"
          >
            <h4 className="text-xl font-semibold mb-4 text-ffc0c6">Contact Us</h4>
            <ul className="space-y-2 text-gray-300">
              <li className="flex items-center">
                <MapPin size={18} className="mr-2 text-ffc0c6" />
                18020 Avenue M, Brooklyn NY, 11230
              </li>
              <li className="flex items-center">
                <Mail size={18} className="mr-2 text-ffc0c6" />
                Contact@piggybackit.com
              </li>
              <li className="flex items-center">
                <Phone size={18} className="mr-2 text-ffc0c6" />
                (929) 299-0816
              </li>
            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="md:col-span-1"
          >
            <h4 className="text-xl font-semibold mb-4 text-ffc0c6">Legal</h4>
            <ul className="space-y-2 text-gray-300">
              <FooterLink to="/terms">Terms of Service</FooterLink>
              <FooterLink to="/privacypolicy">Privacy Policy</FooterLink>
              <FooterLink to="/cookies">Cookie Policy</FooterLink>
              {/* <FooterLink to="/faq">FAQ</FooterLink> */}
            </ul>
          </motion.div>
        </div>

        <motion.div
          className="mt-12 pt-8 border-t border-gray-700 text-center text-gray-400"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <p>&copy; 2024 Piggybackit LLC. All rights reserved.</p>
        </motion.div>
      </div>

      <BackgroundAnimation />
    </footer>
  );
};

const FooterLink = ({ to, children }) => (
  <li>
    <Link
      to={to}
      className="hover:text-ffc0c6 transition duration-300 cursor-pointer"
    >
      {children}
    </Link>
  </li>
);

const SocialIcon = ({ icon: Icon }) => (
  <a href="/" className="text-gray-400 hover:text-ffc0c6 transition duration-300">
    <Icon size={24} />
  </a>
);

const BackgroundAnimation = () => (
  <div className="absolute inset-0 overflow-hidden pointer-events-none">
    {[...Array(20)].map((_, i) => (
      <motion.div
        key={i}
        className="absolute bg-ffc0c6 rounded-full opacity-10"
        style={{
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
          width: `${Math.random() * 100 + 50}px`,
          height: `${Math.random() * 100 + 50}px`,
        }}
        animate={{
          y: [0, -30, 0],
          scale: [1, 1.2, 1],
          opacity: [0.1, 0.2, 0.1],
        }}
        transition={{
          duration: Math.random() * 5 + 5,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      />
    ))}
  </div>
);

export default Footer;
